<template>
  <div>
    <a-spin :spinning="isDetailLoading">
      <a-row :gutter="[15, 15]">
        <a-col :span="24">
          <a-button @click="backList">返回列表</a-button>
          <span class="prompt-name">{{ form.promptName }}</span>
          <span class="prompt-desc">{{ form.promptDesc }}</span>
        </a-col>
        <a-col :span="24">
          <a-row type="flex" justify="space-between">
            <div class="input-wrapper">
              <a-space>
                <a-input-group compact style="text-wrap: nowrap">
                  <a-input addon-before="模型" style="width: 50px" />
                  <a-tree-select
                    v-model="form.aiModel"
                    style="width: 180px"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="promptList"
                    placeholder="请选择模型"
                    tree-default-expand-all
                  >
                  </a-tree-select>
                </a-input-group>
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <div>参数填写范围：50-10000</div>
                    <div>此参数会影响输出结果的字符数</div>
                  </template>
                  <a-input addon-before="最大的token数量" style="width: 228px" v-model="form.maxTokens" />
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <div>参数填写范围：0-2</div>
                    <div>
                      此参数影响模型输出的随机性，随机性值越高，多次询问的回答更有创意，更可能没有事实依据；随机性值越低，多次询问的回答更可能遇到重复的回答，回答更接近事实
                    </div>
                  </template>
                  <a-input addon-before="随机性" style="width: 165px" v-model="form.temperature" />
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <div>范围：0.1-1</div>
                    <div>此参数指在输出结果时，选择可能性最高的前P个词的概率累积和</div>
                  </template>
                  <a-input addon-before="可能性" style="width: 165px" v-model="form.topP" />
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <div>范围：-2 ～2</div>
                    <div>此参数影响模型输出重复词汇的可能性。数值越高，输出结果的重复词越少</div>
                  </template>
                  <a-input addon-before="频率惩罚" style="width: 180px" v-model="form.frequencyPenalty" />
                </a-tooltip>
              </a-space>
            </div>
            <a-space>
              <a-button type="primary" @click="openSavePromptModal('save')">保存prompt</a-button>
              <a-button @click="showHistory">历史记录</a-button>
            </a-space>
          </a-row>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="15">
            <a-col :span="isHistoryVisible ? 20 : 24">
              <a-row :gutter="[15, 15]">
                <a-col :span="8">
                  <a-card size="small" title="系统提示词" style="height: 350px" class="flex-card">
                    <a-textarea
                      style="height: 100%"
                      placeholder="由用户输入"
                      v-model="form.systemPrompt"
                      @change="() => handlePromptInputChange()"
                    ></a-textarea>
                  </a-card>
                </a-col>
                <a-col :span="8">
                  <a-card size="small" title="用户提示词" style="height: 350px" class="flex-card">
                    <a-textarea
                      style="height: 100%"
                      placeholder="由用户输入"
                      v-model="form.userPrompt"
                      @change="() => handlePromptInputChange()"
                    ></a-textarea>
                  </a-card>
                </a-col>
                <a-col :span="8">
                  <a-card size="small" title="参数配置" style="height: 350px" class="flex-card">
                    <a-collapse defaultActiveKey="1" expand-icon-position="right" v-if="form.variableArr.length > 0">
                      <a-collapse-panel key="1" header="第一组">
                        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                          <a-form-model-item v-for="key in form.variableArr" :key="key">
                            <a-tooltip slot="label" :title="key">
                              <div class="param-label">{{ key }}</div>
                            </a-tooltip>
                            <a-input v-model="paramsObject[key]" />
                          </a-form-model-item>
                        </a-form-model>
                      </a-collapse-panel>
                    </a-collapse>
                  </a-card>
                </a-col>
                <a-col :span="24">
                  <a-spin :spinning="isGetResult">
                    <a-card size="small" style="height: 300px" class="flex-card">
                      <div slot="title">
                        <a-row type="flex" justify="space-between">
                          <a-col> 结果 </a-col>
                          <a-col>
                            <a-space>
                              <a-button size="small" type="primary" @click="runPrompt">运行</a-button>
                            </a-space>
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" style="margin-top: 8px">
                          <div class="result-info">
                            <span>时长：{{ Number(resultData.duration).toFixed(0) }}s</span>
                            <span>字符数：{{ resultData.characters }}</span>
                            <span>tokens：{{ resultData.responseTokens }}</span>
                          </div>
                          <a-col>
                            <a-icon
                              type="edit"
                              theme="filled"
                              class="icon-btn"
                              @click="isEdit = !isEdit"
                              style="margin-right: 20px"
                            />
                            <a-icon
                              type="copy"
                              theme="filled"
                              class="icon-btn"
                              v-clipboard:copy="resultData.responseText"
                              v-clipboard:success="onCopySuccess"
                              v-clipboard:error="onCopyError"
                            />
                          </a-col>
                        </a-row>
                      </div>
                      <a-textarea
                        v-if="isEdit"
                        style="height: 100%"
                        placeholder="请输入"
                        v-model="resultData.responseText"
                      ></a-textarea>
                      <pre v-else>{{ resultData.responseText }}</pre>
                    </a-card>
                  </a-spin>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="4" v-show="isHistoryVisible">
              <a-card title="历史记录" size="small" class="flex-card" style="height: 665px">
                <a-icon slot="extra" type="close" style="cursor: pointer" @click="isHistoryVisible = false" />
                <div class="col-flex">
                  <a-spin :spinning="isHistoryListLoading">
                    <div v-for="(item, index) in historyList" :key="index" class="history-item" @click="reShow(item)">
                      <div class="time">{{ item.ctime }}</div>
                      <div>by {{ item.creator }}</div>
                    </div>
                  </a-spin>
                  <center style="margin-bottom: 20px" v-if="historyList.length > 0">
                    <a-pagination
                      size="small"
                      simple
                      :page-size="historyParams.size"
                      v-model="historyParams.page"
                      :total="historyParams.total"
                      @change="getHistoryList"
                    />
                  </center>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-spin>

    <SavePromptModal ref="SavePromptModal" @success="savePromptCallback" />
  </div>
</template>

<script>
import api from '@/api/AIGC.js';
import _ from 'lodash';
import SavePromptModal from './components/SavePromptModal.vue';

export default {
  components: { SavePromptModal },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      isDetailLoading: false,
      isGetResult: false,
      isHistoryListLoading: false,
      isVersionListLoading: false,
      isEdit: false,
      form: {
        id: undefined,
        aiModel: 'gpt-35-turbo-16k',
        maxTokens: '500',
        temperature: '0.7',
        topP: '1',
        frequencyPenalty: '0',
        systemPrompt: undefined,
        userPrompt: undefined,
        promptDesc: '',
        promptName: '',
        variableArr: [],
      },
      paramsObject: {},
      systemParamsObject: {},
      userParamsObject: {},
      resultData: {
        characters: '0',
        duration: '0',
        responseTokens: '0',
        responseText: '',
      },
      isHistoryVisible: false,
      historyParams: {
        aigcPromptId: '',
        page: 1,
        size: 10,
        total: 0,
      },
      historyList: [],
      promptList: [],
    };
  },
  watch: {},
  methods: {
    async handleInit(id) {
      this.form.id = id;
      this.isDetailLoading = true;
      try {
        const { code, message, data } = await api.getPromptDebugDetail(this.form.id);
        this.isDetailLoading = false;
        if (code === 200) {
          Object.assign(this.form, data);
        } else {
          tis.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isDetailLoading = false;
      }
      this.getAllPromptList();
    },
    backList() {
      this.$emit('back');
    },
    /**
     * 打开保存prompt弹窗
     * @param {string} type save:保存， run:运行
     */
    openSavePromptModal(type) {
      this.$refs['SavePromptModal'].openModal(_.pick(this.form, ['promptName', 'promptDesc']), type);
    },
    sumParams() {
      const newParamsObject = {};
      this.form.variableArr.forEach((key) => {
        newParamsObject[key] = this.paramsObject[key];
      });
      this.paramsObject = newParamsObject;
    },
    handlePromptInputChange() {
      const regex = /(?<=\{)(.+?)(?=\})/g;
      // 获取匹配的结果
      const systemResult = (this.form.systemPrompt || '').match(regex) || [];
      const userResult = (this.form.userPrompt || '').match(regex) || [];
      this.form.variableArr = _.uniq([...systemResult, ...userResult]);
      console.log('this.form.variableArr:', this.form.variableArr);
      // 重新计算总参数
      this.sumParams();
    },
    async savePromptCallback(data, type) {
      this.form.promptName = data.promptName;
      this.form.promptDesc = data.promptDesc;
      await this.savePrompt();
      if (type === 'run') {
        this.runPrompt(false);
      }
    },
    async savePrompt(hideLoading) {
      const saveApi = this.form.id ? api.updatePromptDebug : api.addPromptDebug;
      try {
        if (!hideLoading) this.isDetailLoading = true;
        const { code, data, message } = await saveApi(this.form);
        this.isDetailLoading = false;
        if (code === 200) {
          if (!this.form.id) {
            this.form.id = data;
            this.handleInit(data);
            // this.$router.replace({ name: 'promptDebug', query: { id: this.form.id } });
          }
          !hideLoading && this.$message.success('保存成功');
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isDetailLoading = false;
      }
    },
    async runPrompt(isSavePrompt = true) {
      Object.assign(this.resultData, this.$options.data().resultData);
      if (!this.form.id) {
        this.openSavePromptModal('run');
        return;
      }
      this.isGetResult = true;
      if (isSavePrompt) {
        await this.savePrompt(true);
      }
      try {
        const { code, data, message } = await api.runPromptDebug({
          id: this.form.id,
          variableInputInfo: this.paramsObject,
        });
        this.isGetResult = false;
        if (code === 200) {
          this.resultData = data;
        } else {
          this.$message.error(message);
        }
        if (this.isHistoryVisible) {
          this.getHistoryList();
        }
      } catch (error) {
        this.isGetResult = false;
      }
    },
    showHistory() {
      this.isHistoryVisible = true;
      this.getHistoryList();
    },
    async getHistoryList(current) {
      this.historyParams.aigcPromptId = this.form.id;
      this.historyParams.page = current || 1;
      this.isHistoryListLoading = true;
      try {
        const { code, data, message } = await api.getPromptDebugHistoryList(this.historyParams);
        this.isHistoryListLoading = false;
        if (code === 200) {
          this.historyList = data.list;
          this.historyParams.total = data.total;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isHistoryListLoading = false;
      }
    },
    reShow(data) {
      Object.assign(
        this.form,
        _.pick(data, [
          'aiModel',
          'maxTokens',
          'temperature',
          'topP',
          'frequencyPenalty',
          'systemPrompt',
          'userPrompt',
          'promptDesc',
          'promptName',
          'variableArr',
        ])
      );
      Object.assign(this.resultData, _.pick(data, ['characters', 'duration', 'responseText']));
      this.paramsObject = data.variableInputInfo;
    },
    /**
     * 复制成功
     */
    onCopySuccess() {
      this.$message.success('复制成功');
    },
    /**
     * 复制失败
     */
    onCopyError() {
      this.$message.error('复制失败');
    },
    /**
     * 获取模型列表
     */
    async getAllPromptList() {
      const { data, code, msg } = await api.getAiContentModelList();
      if (code == 0) {
        this.promptList = data.map((v, i) => {
          const _data = {
            title: v.first_category,
            value: v.first_category,
            key: v.first_category,
            selectable: false,
            children: v.list.map((vv, ii) => ({
              title: vv.model_name,
              value: vv.model_name,
              key: vv.model_name,
              description: vv.model_description,
            })),
          };
          return _data;
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .flex-card.ant-card {
  display: flex;
  flex-direction: column;
  .ant-card-head {
    background: #fafafa;
  }
  .ant-card-body {
    flex: 1;
    overflow: auto;
  }
  .ant-form-item {
    margin-bottom: 0;
    & + .ant-form-item {
      margin-top: 10px;
    }
  }
}
::v-deep .ant-collapse-header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
::v-deep .ant-form-item-label label {
  display: flex;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-all;
}

.col-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prompt-name {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}
.prompt-desc {
  margin-left: 5px;
  color: gray;
}

.input-wrapper {
  display: flex;
  align-items: center;
}

.param-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.history-item {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
  .time {
    font-weight: bold;
  }
}

.result-info {
  & > span + span {
    margin-left: 30px;
  }
}

.icon-btn {
  cursor: pointer;
}
</style>
