<template>
  <a-modal title="保存prompt" v-model="visible" width="380px" @ok="save" @cancel="onClose" :maskClosable="false">
    <div style="display: flex; align-items: center">
      prompt名称：
      <a-input v-model.trim="form.promptName" placeholder="请输入prompt名称" style="width: 70%" />
    </div>
    <div style="display: flex; align-items: center; margin-top: 15px">
      prompt描述：
      <a-textarea rows="3" v-model.trim="form.promptDesc" placeholder="请输入prompt描述" style="width: 70%" />
    </div>
  </a-modal>
</template>

<script>
import { Sketch } from 'vue-color';
import _ from 'lodash';

export default {
  components: { Sketch },
  data() {
    return {
      visible: false,
      form: {
        promptName: '',
        promptDesc: '',
      },
      type: '',
    };
  },
  methods: {
    openModal(data, type) {
      Object.assign(this.$data, this.$options.data());
      this.form = _.cloneDeep(data);
      this.type = type;
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    save() {
      if (!this.form.promptName) {
        this.$message.warning('prompt名称不能为空!');
        return;
      }
      this.$emit('success', this.form, this.type);
      this.onClose();
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
